<template>
    <div>
        <alert-component class="mt-6" :alert="alerts.history"></alert-component>            
        <v-card
            class="mx-auto"
            outlined
        >
            <v-data-table
                :headers="headers"
                :items="object.data"
                :options.sync="options"
                :server-items-length="object.meta && object.meta.total"
                class="elevation-1 table-striped caption"
                :footer-props="{
                    'items-per-page-text':'Itens por página',
                    'items-per-page-options': [5, 10],
                    'items-per-page-all-text': '',
                    'page-text': '{0}-{1} de {2}'
                }"
            >
                <template v-slot:[`item.detalhes`]="{ item }">
                    <div>    
                        <div class="mb-2 mt-2">
                            <span>
                                <v-chip
                                    label
                                    class="font-weight-medium text-h7"
                                >
                                 {{ formatDateTime(item.created_at) }} 
                                </v-chip>   
                            </span>

                            <span class="ml-2">
                                <v-chip
                                    label
                                    class="font-weight-medium text-h7"
                                >
                                 {{ item.user }} 
                                </v-chip>   
                            </span>
                        </div>
                        <div class="pa-2 old_values">
                           <span class="font-weight-medium">Antigo:</span> {{ formatJson(item.old_values) }}
                        </div>
                        <div class="pa-2 mb-2 new_values">
                           <span class="font-weight-medium">Novo:</span> {{ formatJson(item.new_values) }}
                        </div>
                    </div>                                
                </template>
                
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import AlertComponent from '../alert/AlertComponent.vue'

export default {
    components: {
        AlertComponent
    },

    props: {
        object: {
            require: true,
        },
    },

    watch: {
        options: {
            handler() {
                if(this.options.page > 0){
                    this.loadPromisseAudits();
                }   
            },
        },

        filters () {
            this.options.page = 1
        },

    },

    data () {
        return {
            alerts: {
                history: {
                    show: false,
                    type: 'warning',
                    icon: 'mdi-alert-outline',
                    messages: ['* Atualmente, o histórico exibido estará disponível somente a partir de janeiro de 2025. Para registros anteriores, entre em contato com a AYT.','* A marcação nos portais de propaganda não serão registrados em função das regras de exportação ilimitada ou marcação em lote.','* Os dados dos proprietários poderão ser alterados em diferentes imóveis quando o proprietário possuir mais de um imóvel.']
                },
            },

            params: {},

            options: {
                page: 1,
                itemsPerPage: 10,
            },

            headers: [
                { text: 'DETALHES', align: 'start', value: 'detalhes', },
            ],
        }
    },
    
    methods: {
        loadPromisseAudits(){
            this.params.page = this.options.page
            if(this.options.itemsPerPage && this.options.itemsPerPage > 0){
                this.params.paginate = this.options.itemsPerPage
            }
            this.$emit('callLoadPromisseAudits', this.options)
        }
    }
}
</script>

<style scoped>
.old_values {
  background-color:#FCE4EC;
}

.new_values {
  background-color:#F1F8E9;
}

</style>
