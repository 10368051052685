<template>
    <div>
        <alert-component :alert="alerts.filter"></alert-component>
        <v-container>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field
                        v-model="params.codigo"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Código</span>
                        </template> 
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="params.year"
                        :items="items.years"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Ano</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-btn
                        dark
                        color="primary"
                        @click="propertyFacs"
                        :class="$css_button"
                    >
                        Pesquisar
                    </v-btn>
                </v-col>
            </v-row>
            <v-row v-if="dataItems.data && dataItems.data.length > 0">
                <v-col>
                    <v-card
                        class="mx-auto"
                        outlined
                    >
                        <v-data-table
                            :headers="headers"
                            :items="dataItems.data"
                            :options.sync="options"
                            :server-items-length="dataItems.meta && dataItems.meta.total"
                            class="elevation-0 table-striped"
                            :footer-props="{    
                                'items-per-page-text':'Itens por página',
                                'items-per-page-options': [5,10],
                                'items-per-page-all-text': '',
                                'page-text': '{0}-{1} de {2}'
                            }"
                        >
                            <template v-slot:[`item.codigo`]="{ item }">
                                <span :class="$css_report_list">{{ item.codigo }}</span>
                            </template>

                            <template v-slot:[`item.tipo_imovel`]="{ item }">
                                <span :class="$css_report_list">{{ item.tipo_imovel }}</span>

                                <div v-if="item.sub_tipo_imovel && item.sub_tipo_imovel != 'Apartamento Padrão'  && item.sub_tipo_imovel != 'Casa Padrão'" style="font-size:12px">
                                    ({{ item.sub_tipo_imovel }})
                                </div>
                            </template>

                            <template v-slot:[`item.endereco`]="{ item }">
                                <span :class="$css_report_list">
                                    {{ item.endereco }}
                                    <span v-if="item.numero">, {{ item.numero }} </span>
                                    <span v-if="item.apartamento"> - Apto: {{ item.apartamento }} </span>
                                    <span v-if="item.complemento"> - Compl: {{ item.complemento }} </span>
                                </span>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-card-actions>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="pink" dark depressed v-bind="attrs" v-on="on" @click.prevent="openNewTabWithRouter(item.codigo)" class="ml-8">
                                                {{ item.total }}
                                            </v-btn>
                                        </template>
                                        <span>Clique para visualizar as FACS</span>
                                    </v-tooltip>
                                </v-card-actions>    
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import AlertComponent from '@/components/alert/AlertComponent.vue'

export default {
    components: {
        AlertComponent
    },

    created() {
        this.propertyFacs()
    },

    watch: {
        options: {
            handler() {
                if(this.options.page > 0){
                    this.propertyFacs();
                }   
            },
        },

        filters () {
            this.options.page = 1
        },
    },

    data () {
        return {
            dataItems: {
                data: []
            },

            options: {
                page: 1,
                itemsPerPage: 10,
            },

            params: {
                codigo: '',
                year: '',
                page: '',
            },

            headers: [
                { text: 'CODIGO', value: 'codigo' , sortable: false},  
                { text: 'TIPO', value: 'tipo_imovel' , sortable: false},
                { text: 'ENDEREÇO', value: 'endereco' , sortable: false},  
                { text: 'TOTAL DE FACS', value: 'actions', sortable: false }
            ],

            alerts: {
                filter: {
                    type: 'warning',
                    icon: 'mdi-alert-outline',
                    messages: ['Utilize o filtro codigo ou ano para filtrar o resultado caso desejado']
                },
            },

            items: {
                years: []
            }
        }
    },  
    
    mounted() {
        // Preenche o array com o ano atual e os 5 anteriores
        const currentYear = new Date().getFullYear();
        for (let i = 0; i <= 5; i++) {
            this.items.years.push(currentYear - i);
        }
    },

    methods: {
        propertyFacs(){
            if(this.params.codigo || this.params.codigo != ''){
                this.params.page = 1
            } else {
                this.params.page = this.options.page
                if(this.options.itemsPerPage && this.options.itemsPerPage > 0){
                    this.params.paginate = this.options.itemsPerPage
                }
            }
            this.$store.dispatch('propertyFacs', this.params) 
                        .then(response => {
                            this.dataItems = response
                        })
        },
        
        openNewTabWithRouter(codigo) {
            // Cria a URL baseada nas rotas definidas
            const routeData = this.$router.resolve({
                name: "admin.facs.index", // Nome da rota definida no roteador
                query: {
                    multiple_codigo_property: codigo
                }, // Parâmetros da query string
            });

            // Abre a URL gerada pelo roteador em uma nova guia
            window.open(routeData.href, "_blank");
        },
    }
}
</script>
