<template>
    <div>
        <alert-component v-if="!item.id && total >= tenant[0].app_plan.users" :alert="alerts.limit"></alert-component>
        <v-form ref="form" v-if="item.id || total < tenant[0].app_plan.users">
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.name"
                        label="Nome"
                        :rules="[rules.required]"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        v-model="item.profile"
                        :items="items.profiles"
                        :rules="[rules.required]"
                        label="Perfil"
                        outlined
                        dense
                        :class="$css_field"
                        :filled="me.profile != 'Master' ? true : false"
                        :readonly="me.profile != 'Master' ? true : false"
                    ></v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-autocomplete
                        v-model="item.branch_id"
                        :items="branches.data"
                        outlined
                        dense
                        label="Unidade"
                        item-text="descricao"
                        item-value="id"
                        :class="$css_field"
                        :rules="[rules.required]"
                        :filled="me.profile != 'Master' ? true : false"
                        :readonly="me.profile != 'Master' ? true : false"
                    ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row dense>    
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.email"
                        :rules="[rules.required, rules.email]"
                        label="E-mail"
                        outlined
                        dense
                        :class="$css_field"
                    >
                    </v-text-field>
                </v-col> 
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.telefone1"
                        label="Celular"
                        :rules="[rules.required]"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                    </v-text-field>
                </v-col>
            </v-row> 

            <v-row dense>
                <v-spacer></v-spacer>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="text-right">
                    <v-btn
                        dark
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                    Gravar
                    </v-btn>
                </v-col>    
            </v-row>

        </v-form>       
    </div>
</template>

<script>
import { PROFILES } from '../config_users/config_users.js'
import AlertComponent from '@/components/alert/AlertComponent.vue'


export default {
    components: {
        AlertComponent,
    },

    created() {
        this.setObject()
        this.loadBranches()
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {   
            this.setObject()
        },
    },

    computed: {
        branches() {
            return this.$store.state.branches.items    
        },

        tenant() {
            return this.$store.state.tenants.items.data    
        },
        me () {
          return this.$store.state.auth.me
        },
        store_user() {
            return this.$store.state.users.store    
        },
    },  

    data () {
        return {
            alerts: {
                limit: {
                    show: '',
                    type: 'error',
                    icon: 'mdi-alert-outline',
                    messages: ['O limite de cadastro foi atingido. Entre em contato com AYT para migrar de plano ou exclua alguns registros desnecessários.']
                },
            },

            total: 0,

            params: {
                paginate: 10,
            },

            item: {},

            items: {
                profiles: PROFILES
            },
            
            rules: {
                required: value => !!value || 'Required',
                min:  v => (v && v.length >= 8) || 'Mininmo de 8 caracteres',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },

            params_branch: {
                paginate: 'false'
            }
        }
    },

    methods: {

        setObject(){
            if(this.object && this.object.id != undefined){
                this.item = this.object
            }else{
                this.loadPromisseItems()
            }
        },

        loadPromisseItems(){
            this.$store.dispatch('loadPromisseUsers', this.params)
                        .then(response => {
                           this.total = response.meta.total
                        })
            
        },

        loadBranches(){
            this.$store.dispatch('loadBranches', this.params_branch)
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.storeUpdate(this.item)
            }
        },

        storeUpdate(item){
            const action = item.id ? 'updateUser' : 'storeUser' 
            this.$store.dispatch(action, item)
                        .then(response => {
                            this.item = response.data   
                            
                            if(action == 'storeUser'){
                                this.$store.commit('STORE_USER', true)
                            }

                            this.$snotify.success('Cadastro com sucesso!')
                            this.$emit('callLoadItem', this.item.id)
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
                   
        }, 
    },
}
</script>

<style scoped>

</style>