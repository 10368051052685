<template>
    <div>
        <show-plan-component v-if="object.plans && object.plans.length > 0" class="mt-4" :object="object"></show-plan-component>
        <show-tower-component v-if="object.towers && object.towers.length > 0" class="mt-4" :object="object"></show-tower-component>
    </div>
</template>

<script>
import ShowPlanComponent from '../show/show_partials/ShowPlanComponent.vue'
import ShowTowerComponent from '../show/show_partials/ShowTowerComponent.vue'

export default {
    components: {
        ShowPlanComponent,
        ShowTowerComponent
    },

    props: {
        object: {
            require: true,
        },
    }, 

}
</script>