<template>
    <div>
        <div v-if="permissions.colaborator == false">
            <alert-component :alert="alerts.colaborator"></alert-component>
            <v-btn
                dark
                color="purple"
                @click="openUserRequest()"
                :class="$css_button"
            >
                SOLICITAR ALTERAÇÃO
            </v-btn>
        </div>

        <div v-else>
            <div v-if="!object.id" class="mt-8">
               <main-component :object="item" @callLoadItem="loadItem" ref="main"></main-component>
            </div>
            <div v-else>
                <alert-component v-if="item.status == 'Ativo' && item.publicar_imovel != 'Sim'" :alert="alerts.publicar_imovel_nao"></alert-component>

                <v-tabs
                    v-model="active_tab"
                    show-arrows 

                >
                    <v-tab href="#tab-1" class="caption"> Endereço, Tipo de Imóvel...</v-tab>
                    <v-tab href="#tab-2" class="caption"> Dorm, Area, Valores...</v-tab>
                    <v-tab href="#tab-3" class="caption" v-if="this.item.tipo_imovel != 'Terreno' && this.item.tipo_imovel != 'Área'"> Área Privativa</v-tab>
                    <v-tab href="#tab-4" class="caption"> Infra</v-tab>
                    <v-tab href="#tab-5" class="caption" v-if="this.item.tipo_imovel != 'Terreno' && this.item.tipo_imovel != 'Área'"> Lazer </v-tab>
                    <v-tab href="#tab-6" class="caption"> Colaboradores </v-tab>
                    <v-tab href="#tab-7" class="caption"> Locais </v-tab>
                    <v-tab href="#tab-8" class="caption"> Fotos </v-tab>
                    <v-tab href="#tab-9" class="caption"> Publicidade </v-tab>
                    <v-tab href="#tab-10" class="caption"> Videos </v-tab>
                    <v-tab href="#tab-11" class="caption"> Placa </v-tab>
                    <v-tab href="#tab-12" class="caption"> Portais </v-tab>
                    <v-tab href="#tab-13" class="caption"> Proprietário </v-tab>
                    <v-tab href="#tab-14" class="caption"> Status </v-tab>
                    <v-tab href="#tab-15" class="caption"> Arquivos </v-tab>
                    <v-tab href="#tab-200" class="caption" @click="loadPromisseAudits()"> Histórico </v-tab>
                </v-tabs>
            

                <v-tabs-items v-model="active_tab">
                    <v-tab-item value="tab-1">
                        <v-card flat class="pa-8">
                            <main-component :object="item" @callLoadItem="loadItem" ref="main" key="202311282237"></main-component>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item value="tab-2">
                        <v-card flat class="pa-8">
                            <price-component :object="item" @callLoadItem="loadItem"></price-component>
                        </v-card>    
                    </v-tab-item>

                    <v-tab-item value="tab-3">
                        <v-card flat class="pa-8">
                            <property-item-component :object="private_areas" @callLoadItem="loadItem" ref="property_items"></property-item-component>
                        </v-card>    
                    </v-tab-item>

                    <v-tab-item value="tab-4">
                        <v-card flat class="pa-8">
                            <property-item-component :object="infrastructures" @callLoadItem="loadItem" ref="property_items"></property-item-component>
                        </v-card>    
                    </v-tab-item>

                    <v-tab-item value="tab-5">
                        <v-card flat class="pa-8">
                            <property-item-component :object="recreations" @callLoadItem="loadItem" ref="property_items"></property-item-component>
                        </v-card>    
                    </v-tab-item>

                    <v-tab-item value="tab-6">
                        <v-card flat class="pa-8">
                            <colaborator-component :object="colaborators" @callLoadItem="loadItem" ref="colaborators"></colaborator-component>
                        </v-card>    
                    </v-tab-item>

                    <v-tab-item value="tab-7">
                        <v-card flat class="pa-8">
                            <place-component :object="places" @callLoadItem="loadItem" ref="places"></place-component>
                        </v-card>    
                    </v-tab-item>

                    <v-tab-item value="tab-8">
                        <v-card flat class="pa-8">
                            <drop-zone-v20-component id="20" :object="photos" @callLoadItem="loadItem" :key="key.dropzone"></drop-zone-v20-component>
                            <!--drop-zone-v18-component v-if="
                                tenant[0].id == '01ddf3b0-0eff-4bb3-a118-8f7fad06e6f0'
                                || tenant[0].id == '820fab25-e24f-4df2-b945-2b9be3e9e7e1'
                                || tenant[0].id == 'ecd1c26c-1fbd-43b8-a0ab-67987d9035eb'
                                || tenant[0].id == 'd6b032da-3231-4297-b88f-addbec5d77ca'
                                || tenant[0].id == 'cf0b8be2-c262-4e8f-bfa9-c01978fe6321'
                                || tenant[0].id == 'a754e210-e9a1-4ef6-b111-7e15779c19be'
                            " id="119" :object="photos" @callLoadItem="loadItem" :key="key.dropzone"></drop-zone-v18-component>
                            <drop-zone-component-new-2 v-else id="119" :object="photos" @callLoadItem="loadItem" :key="key.dropzone"></drop-zone-component-new-2-->
                        </v-card>    
                    </v-tab-item>

                    <v-tab-item value="tab-9">
                        <v-card flat class="pa-8">
                            <publicity-component :object="item" @callLoadItem="loadItem"></publicity-component>
                        </v-card>    
                    </v-tab-item>

                    <v-tab-item value="tab-10">
                        <v-card flat class="pa-8">
                            <video-component :object="videos" @callLoadItem="loadItem"></video-component>
                        </v-card>    
                    </v-tab-item>

                    <v-tab-item value="tab-11">
                        <v-card flat class="pa-8">
                            <board-component :object="boards" @callLoadItem="loadItem"></board-component>
                        </v-card>    
                    </v-tab-item>

                    <v-tab-item value="tab-12">
                        <v-card flat class="pa-8">
                            <portal-component :object="portals" @callLoadItem="loadItem"></portal-component>
                        </v-card>    
                    </v-tab-item>

                    <v-tab-item value="tab-13">
                        <v-card flat class="pa-8">
                            <property-owner-component :object="property_owners" @callLoadItem="loadItem"></property-owner-component>
                        </v-card>    
                    </v-tab-item>

                    <v-tab-item value="tab-14">
                        <v-card flat class="pa-8">
                            <status-component :object="item" :permissions="permissions" @callLoadItem="loadItem" @callChangeTab="changeTab" @callOpenUserRequest="openUserRequest"></status-component>
                        </v-card>    
                    </v-tab-item>

                    <v-tab-item value="tab-15">
                        <v-card flat class="pa-8">
                            <upload-component :object="files" :permissions="permissions" @callLoadItem="loadItem"></upload-component>
                        </v-card>    
                    </v-tab-item>

                    <v-tab-item value="tab-200">
                        <v-card flat class="pa-8">
                            <audit-component :object="audits.items" @callLoadPromisseAudits="loadPromisseAudits"></audit-component>
                        </v-card>    
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </div>  

        <!-- Dialog -->
        <v-dialog 
            class="d-print-none"
            v-model="dialog.user_requests" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
                class="d-print-none"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    Solicitação de alteração de imóvel
                    <span v-if="item.codigo" class="ml-2">- Código {{ item.codigo }} </span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="openCloseDialog('user_requests')">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <store-user-request-component :object="user_requests"></store-user-request-component>
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->

    </div>  
</template>


<script>
import { COLABORATOR_DESCRIPTION_ITEMS } from '../config_properties/config_properties.js'

import MainComponent from './MainComponent.vue'
import PriceComponent from './PriceComponent.vue'
import PropertyItemComponent from '../../property_items/PropertyItemComponent.vue'
import ColaboratorComponent from '@/components/colaborators/ColaboratorComponent.vue'
import PlaceComponent from '@/components/places/PlaceComponent.vue'
import PublicityComponent from './PublicityComponent.vue'
import VideoComponent from '@/components/videos/VideoComponent.vue'
import BoardComponent from '@/components/boards/BoardComponent.vue'
import PortalComponent from '@/components/portals/PortalComponent.vue'
import PropertyOwnerComponent from '@/components/property_owners/PropertyOwnerComponent.vue'
import StatusComponent from './StatusComponent.vue'
import AlertComponent from '@/components/alert/AlertComponent.vue'
import DropZoneComponentNew2 from '@/components/dropzone/DropZoneComponentNew2.vue'
import StoreUserRequestComponent from '@/components/user_requests/partials/StoreUserRequestComponent.vue'
import UploadComponent from '@/components/upload/UploadComponent.vue'
import DropZoneV18Component from '@/components/dropzone/DropZoneV18Component.vue'
import DropZoneV20Component from '@/components/dropzone/DropZoneV20Component.vue'
import AuditComponent from '@/components/audits/AuditComponent.vue'

export default {
    created() {
        this.setTab()
        this.setObject()
    },

    components: {
        MainComponent,
        PriceComponent,
        PropertyItemComponent,
        ColaboratorComponent,
        PlaceComponent,
        PublicityComponent,
        VideoComponent,
        BoardComponent,
        PortalComponent,
        PropertyOwnerComponent,
        StatusComponent,
        AlertComponent,
        DropZoneComponentNew2,
        StoreUserRequestComponent,
        UploadComponent,
        DropZoneV18Component,
        DropZoneV20Component,
        AuditComponent
    },

    props: {
        object: {
            require: false,
        },

        permissions: {
            require: false,
        },
    }, 

    watch: {
        object: {
            handler:function() {
                if(this.item.id != this.object.id){
                    this.setTab()
                }
                this.setObject()
            },
            deep:true
        },
  
        item () {       
            //this.setObject()
            //deep:true
        }
    },

    computed: {
        me () {
          return this.$store.state.auth.me
        },

        tenant() {
            return this.$store.state.tenants.items.data    
        },
    },

    data () {
        return {
            key: {
                dropzone: 3,
            },
            
            active_tab: 'tab-1',
            item: {},
            dialog: {
                user_requests: false
            },

            alerts: {
                colaborator: {
                    type: 'error',
                    icon: 'mdi-alert-outline',
                    messages: ['Você nāo possui permissāo de acesso a ediçāo desse imóvel. Solicite alteração nesse imóvel clicando no botão abaixo.']
                },

                publicar_imovel_nao: {
                    type: 'warning',
                    icon: 'mdi-alert-outline',
                    messages: ['O campo "Publicar imóvel" na ABA "Publicidade" está cadastrado com a opção "Não". Assim, o imóvel não será exibido no site e nos portais.']
                },
            },

            private_areas: {
                title: "",
                model: "properties",
                model_property: "private_areas",
                order: "description",
                method: "storePrivateArea",
                item_id: "",
                items: []     
            },

            infrastructures: {
                model: "properties",
                model_property: "infrastructures",
                order: "description",
                method: "storeInfrastructure",
                tipos: [this.object.tipo_imovel],
                regioes: [this.object.regiao],
                item_id: "",
                items: [],
                editable: 'true' 
            },

            recreations: {
                model: "properties",
                model_property: "recreations",
                order: "description",
                method: "storeRecreation",
                tipos: [this.object.tipo_imovel],
                regioes: [this.object.regiao],
                item_id: "",
                items: [],
                editable: 'true'
            },

            colaborators: {
                model: "properties",
                descriptions: COLABORATOR_DESCRIPTION_ITEMS,
                item_id: "",
                items: []     
            },

            places: {
                model: "properties",
                item_id: "",
                cidade: "",
                estado: "",
                items: []     
            },

            photos:{
                model: 'properties',
                item_id: '',
                items: [],
                items_condominium: '',
                watermark: '',
                condominium_id: ''
            },

            videos:{
                model: 'properties',
                item_id: '',
                items: ''
            },

            boards:{
                model: 'properties',
                item_id: '',
                items: ''
            },

            portals: {
                item_id: '',
                items: []
            },

            property_owners: {
                property_id: '',
                items: []
            },

            user_requests: {
                model: 'properties',
                item_id: '',
                subject: 'Solicitação de alteração',
                object: {}
            },

            files: {
                multiple: true,
                model: 'properties',
                item_id: '',
                items: '',
                extensions: '.pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg',
                label_extensions: 'File  (pdf, docx, xlsx e jpg)',
                description_text: false
            },

            audits: {
                items: []
            }
        }
    },

    methods: {
        setNull(){
            if(this.permissions.colaborator == true){
                this.$refs.main.newAddress()
            }
        },

        loadItem(id){
            this.$emit('callLoadItem', id)
        },
        
        setTab(){
            this.active_tab = 'tab-1'
        },

        changeTab(tab){
            this.active_tab = tab
        },

        setObject(){
            this.$forceUpdate()
            this.item = this.object

            this.private_areas.item_id = this.item.id
            this.private_areas.items = this.item.private_areas

            this.infrastructures.item_id = this.item.id
            this.infrastructures.items = this.item.infrastructures
            this.infrastructures.tipos = [this.item.tipo_imovel]
            this.infrastructures.regioes = [this.item.regiao]
            
            this.recreations.item_id = this.item.id
            this.recreations.items = this.item.recreations
            this.recreations.tipos = [this.item.tipo_imovel]
            this.recreations.regioes = [this.item.regiao]

            this.colaborators.item_id = this.object.id
            this.colaborators.items = this.object.colaborators

            this.places.item_id = this.object.id
            this.places.cidade = this.object.cidade
            this.places.estado = this.object.estado
            this.places.items = this.object.places

            // photos
            this.photos.item_id = this.object.id
            this.photos.condominium_id = this.object.condominium_id
            this.photos.items = this.object.photos
            this.photos.watermark = this.tenant[0].watermark
            
            
            // video
            this.videos.item_id = this.object.id
            this.videos.items = this.object.videos
            
            // boards
            this.boards.item_id = this.object.id
            this.boards.items = this.object.boards

            // portals
            this.portals.item_id = this.object.id
            this.portals.items = this.object.portals

            // property_owners
            this.property_owners.property_id = this.object.id
            this.property_owners.items = this.object.property_owners

            this.infrastructures.editable = 'true'
            this.recreations.editable = 'true'
            this.photos.items_condominium = []
            
            if(this.item.condominium && this.item.condominium.id != ''){
                // infrastructures
                this.infrastructures.items = this.item.condominium.infrastructures 
                this.infrastructures.editable = 'false'
                // recreations
                this.recreations.items = this.item.condominium.recreations 
                this.recreations.editable = 'false'
                // photos
                this.photos.items_condominium = this.item.condominium.photos
            }

            // files
            this.files.item_id = this.object.id
            this.files.items = this.object.files                
        },

        openUserRequest(){ 
            this.user_requests.item_id = this.object.id
            this.user_requests.object = this.object
            this.dialog.user_requests = true
        },

        openCloseDialog(model){
            if(model == 'user_requests'){
                this.dialog.user_requests = !this.dialog.user_requests
            }
        },

        loadPromisseAudits(param = null){
            const params = {}
            params.model = 'properties'
            params.codigo = this.object.codigo

            if(param){
                if(param.page && param.page > 0){
                   params.page = param.page     
                }
                if(param.paginate && param.paginate > 0){
                   params.paginate = param.paginate     
                }
            }

            this.$store.dispatch('loadPromisseAudits', params)
                        .then(response => {
                            this.audits.items = response
                        })
                        .catch(error => {
                            console.log(error)
                        })
        }
        
    },
}
</script>

<style scoped>

</style>