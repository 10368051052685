var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"pa-3 ma-0",attrs:{"outlined":"","min-height":"114"}},[_c('v-card-title',{staticClass:"pa-0 ma-0 font-weight-light body-2"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-home-city")]),_vm._v(" Solicitações internas ")],1),_c('v-row',{staticClass:"mt-1",attrs:{"dense":""}},_vm._l((_vm.dashboard.user_requests),function(dash){return _c('v-col',{key:dash.status,staticClass:"text-h4 font-weight-light text-center"},[(dash.total >= 0)?_c('router-link',{attrs:{"to":{ 
                                name: 'admin.userrequests.index', 
                                params: {
                                    status: dash.status,  
                                    user_request: _vm.me.id                           
                                } 
                            }}},[_c('span',{class:_vm._f("statusUserRequest")(dash.status,'--text')},[_vm._v(_vm._s(dash.total))]),_c('div',{staticClass:"caption black--text"},[_vm._v(_vm._s(dash.status))])]):_vm._e()],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }