var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"pa-3 ma-0",attrs:{"outlined":"","min-height":"114"}},[_c('v-card-title',{staticClass:"pa-0 ma-0 font-weight-light body-2"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-home-city")]),_vm._v(" Imóveis ")],1),_c('v-row',{staticClass:"mt-1",attrs:{"dense":""}},[_vm._l((_vm.dashboard.properties),function(dash){return _c('v-col',{key:dash.status,staticClass:"text-h4 font-weight-light text-center"},[(dash.total > 0)?_c('router-link',{attrs:{"to":{ 
                                name: 'admin.properties.index', 
                                params: {
                                    status: dash.status,  
                                    multiple_colaborator: _vm.me.profile == 'Corretor' && dash.status != 'Ativo' ? [_vm.me.id] : ''                           
                                } 
                            }}},[_c('span',{class:_vm._f("statusProperty")(dash.status,'--text')},[_vm._v(_vm._s(dash.total))]),_c('div',{staticClass:"caption black--text"},[_vm._v(_vm._s(dash.status))])]):_vm._e()],1)}),(_vm.dashboard.venda_recentes && _vm.dashboard.venda_recentes[0].total > 0)?_c('v-col',{staticClass:"text-h4 font-weight-light text-left"},[_c('router-link',{attrs:{"to":{ 
                                    name: 'admin.properties.index', 
                                    params: {
                                        status: 'Ativo', 
                                        setor: 'Venda', 
                                        data_cadastro_to: _vm.dashboard.venda_recentes[0].data_cadastro_to,
                                        data_cadastro_from: _vm.dashboard.venda_recentes[0].data_cadastro_from                          
                                    } 
                                }}},[_c('span',[_vm._v(_vm._s(_vm.dashboard.venda_recentes[0].total))])]),_c('div',{staticClass:"caption black--text"},[_vm._v("Recente Vendas")])],1):_vm._e(),(_vm.dashboard.locacao_recentes && _vm.dashboard.locacao_recentes[0].total > 0)?_c('v-col',{staticClass:"text-h4 font-weight-light text-left"},[_c('router-link',{attrs:{"to":{ 
                                    name: 'admin.properties.index', 
                                    params: {
                                        status: 'Ativo', 
                                        setor: 'Locacao', 
                                        data_cadastro_to: _vm.dashboard.locacao_recentes[0].data_cadastro_to,
                                        data_cadastro_from: _vm.dashboard.locacao_recentes[0].data_cadastro_from                          
                                    } 
                                }}},[_c('span',[_vm._v(_vm._s(_vm.dashboard.locacao_recentes[0].total))])]),_c('div',{staticClass:"caption black--text"},[_vm._v("Recente Locação")])],1):_vm._e()],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }