<template>
    <div>
        <div v-if="$vuetify.breakpoint.mobile">
            <v-row v-for="item in object.data" :key="item.id">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-card
                        outlined
                        class="pa-2"
                    >
                        <v-row dense>
                            <v-col class="text-left" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <span>
                                    <v-chip
                                        label
                                        small
                                        color="blue lighten-5" 
                                    >
                                    <span style="font-size:12px">{{ item.codigo }}</span> 
                                    </v-chip>   
                                </span>
                                <span class="ml-2">
                                    <v-chip
                                        :color="item.status | statusFac"
                                        label
                                        small
                                    >
                                        {{ item.status }}
                                    </v-chip> 
                                </span>
                            </v-col>  
                        </v-row>

                        <v-row dense class="mt-3 pa-2">
                            <v-col class="text-left" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <div :class="$css_show_description">
                                    <span :class="$css_show_title">Finalidade:</span>
                                    {{ item.finalidade}}
                                </div> 
                            </v-col>
                            <v-col class="text-left" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <div :class="$css_show_description">
                                    <span :class="$css_show_title">Nome:</span> 
                                    {{ item.nome }}
                                </div> 
                            </v-col>
                            <v-col class="text-left" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <div :class="$css_show_description">
                                    <span :class="$css_show_title">Como conheceu:</span> 
                                    {{ item.como_conheceu }}
                                </div> 
                            </v-col>
                            <v-col class="text-left" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <div :class="$css_show_description">
                                    <span :class="$css_show_title">DT. CAD.:</span> 
                                    {{ item.created_at }}
                                </div> 
                            </v-col>
                            <v-col class="text-left" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <div :class="$css_show_description">
                                    <span :class="$css_show_title">DT. AT.:</span> 
                                    {{ item.updated_at }}
                                </div> 
                            </v-col>
                        </v-row>
                      

                        <v-row dense>
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <v-card-actions class="text-right"> 
                                    <v-spacer></v-spacer>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click.prevent="showItem(item.id)" class="ml-8">
                                                <v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Visualizar</span>
                                    </v-tooltip>
                                    
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click.prevent="editItem(item.id)" class="ml-8">
                                                <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Edit</span>
                                    </v-tooltip>

                                    <span v-if="tenant[0].json_params && tenant[0].json_params.show_trash && tenant[0].json_params.show_trash == 'false'">

                                    </span>
                                    <span v-else>
                                        <v-tooltip top  v-if="me.profile == 'Master' || me.profile == 'Gerente'">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon @click.prevent="destroy(item)" class="ml-8">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Delete</span>
                                        </v-tooltip>
                                    </span>
                                </v-card-actions>  
                            </v-col>    
                        </v-row>

                    </v-card>
                </v-col>    
            </v-row>

            <v-row v-if="object.meta && object.meta.total > 0">
                <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
                    <v-btn
                        v-if="object.meta.to > 1 && object.meta.total > this.options.itemsPerPage"
                        depressed
                        class="mt-2 mb-3 rounded-0" 
                        color="error"
                        @click="linkPagination(object.meta.to - 1)"
                        block
                    >
                        <v-icon left>
                            mdi-skip-previous
                        </v-icon>
                        Anterior
                    </v-btn>
                </v-col>
                <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
                    <v-btn
                        v-if="object.meta.to < object.meta.total"
                        depressed
                        color="primary"
                        class="mt-2 mb-3 rounded-0"
                        @click="linkPagination(object.meta.to + 1)"
                        block
                    >
                        Próximo
                        <v-icon left class="ma-1">
                            mdi-skip-next
                        </v-icon>
                    </v-btn>
                </v-col>    
            </v-row>
        </div>

    <!-- PC -->
        <v-row class="mt-4" v-if="!$vuetify.breakpoint.mobile">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-card
                    class="mx-auto"
                    outlined
                >
                
                <v-data-table
                    :headers="headers"
                    :items="object.data"
                    :options.sync="options"
                    :server-items-length="object.meta && object.meta.total"
                    class="elevation-1 table-striped"
                    :footer-props="{    
                        'items-per-page-text':'Itens por página',
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                >
                    <template v-slot:[`item.details`]="{ item }">
                        <div class="mb-3 mt-3">    
                            <div>
                                <span>
                                    <v-chip
                                        color="blue"
                                        label
                                        text-color="white"
                                        class="font-weight-medium text-h7"
                                    >
                                        COD: {{ item.codigo }} 
                                    </v-chip>   
                                </span>
                                <span class="ml-2">
                                    <v-chip
                                        :color="item.status | statusFac"
                                        label
                                        text-color="black"
                                        class="font-weight-medium text-h7"
                                    >
                                        {{ item.status }}
                                    </v-chip> 
                                </span>
                                <span class="ml-2 font-weight-medium">
                                    Finalidade:
                                    <span :class="$css_report_list + ' font-weight-medium'">{{ item.finalidade }}</span> 
                                </span>  
                                <span class="ml-3 font-weight-medium">  
                                    DT. CAD: 
                                    <span v-if="item.created_at"> {{ item.created_at }}</span>
                                </span>
                                <span class="ml-2 font-weight-medium">  
                                    DT. AT: 
                                    <span v-if="item.updated_at"> {{ item.updated_at }}</span>
                                </span>   
                            </div>
                            <div class="mt-2">
                                <span :class="$css_subtitle_2 + ' text-h6'">
                                    Nome: {{ item.nome }}
                                </span> 
                            </div>
                            <div>
                                <v-row dense>
                                    <v-col cols="12" xs="1" sm="1" md="2" lg="2" xl="2">
                                        <span :class="$css_report_list">
                                            Como conheceu: {{ item.como_conheceu }}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" xs="1" sm="1" md="3" lg="3" xl="3">
                                        <span :class="$css_report_list + ' ml-2'">
                                            Classificação: {{ item.classificacao }}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn :class="$css_button" block depressed outlined @click.prevent="showItem(item.id)">
                                                    <v-icon left v-bind="attrs" v-on="on">mdi-eye</v-icon> FICHA
                                                </v-btn>
                                            </template>
                                            <span>Visualizar Ficha</span>
                                        </v-tooltip>
                                    </v-col>
                                    <v-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn :class="$css_button" block depressed outlined @click.prevent="editItem(item.id)">
                                                    <v-icon left v-bind="attrs" v-on="on">mdi-pencil</v-icon> EDITAR
                                                </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                    </v-col>
                                
                                    <v-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2">
                                        <span v-if="tenant[0].json_params && tenant[0].json_params.show_trash && tenant[0].json_params.show_trash == 'false'">

                                        </span>
                                        <span v-else>
                                            <v-tooltip top v-if="me.profile == 'Master' || me.profile == 'Gerente'">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn :class="$css_button" block depressed outlined @click.prevent="confirmDestroy(item)">
                                                        <v-icon left v-bind="attrs" v-on="on">mdi-delete</v-icon> EXCLUIR
                                                    </v-btn>
                                                </template>
                                                <span>Excluir</span>
                                            </v-tooltip>
                                        </span>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>                                    
                    </template>
                </v-data-table>
                </v-card>
            </v-col>
        </v-row>

    </div>
</template>

<script>
import CarouselComponent from '../../carousel/CarouselComponent.vue'

export default {

    components: {
        CarouselComponent
    },

    props: {
        object: {},

        filters: {},
    }, 

    watch: {
        options: {
            handler() {
                if(this.options.page > 0){
                    this.loadItems();
                }   
            },
        },

        filters () {
            this.options.page = 1
        },

    },

    computed: {
        me () {
          return this.$store.state.auth.me
        },

        tenant() {
            return this.$store.state.tenants.items.data    
        },
    }, 

    data () {
        return {
            dialog_carousel: false,
            item: {},

            options: {
                page: 1,
                itemsPerPage: 10,
            },

            headers: [
                { text: 'Dados da FAC', value: 'details' , sortable: false}, 
                /* 
                { text: 'STATUS', value: 'status' , sortable: false},  
                { text: 'FINALIDADE', value: 'finalidade' , sortable: false},  
                { text: 'CLIENTE', value: 'nome' , sortable: false},
                { text: 'COMO CONHECEU', value: 'como_conheceu' , sortable: false},
                { text: 'AÇÕES', value: 'actions', sortable: false },
                 */
            ],

            confirmDelete: {
                id: '',
                status: false,
                name: '',
                text: '',
                destroyfunction: '',
            },
        }    
    },

    filters: {
        userProfileColor (value) {
            if(!value) return ''
            if(value == 'Gerente') return 'blue lighten-4'
            else if(value == 'Master') return 'orange lighten-4'
            else if(value == 'Corretor') return 'green lighten-4'
            else return ''
        },
    },

    methods: {
        loadItems(){
            this.params = this.filters 
            this.params.page = this.options.page
            if(this.options.itemsPerPage && this.options.itemsPerPage > 0){
                this.params.paginate = this.options.itemsPerPage
            }
            this.$emit('callLoadItems', this.params)   
        },

        openCloseDialog() {
            this.dialog = !this.dialog
        },

        editItem(id){
            this.$emit('callEditItem', id)
        },

        showItem(id){
            this.$emit('callShowItem', id)
        },

        confirmDestroy(item) {
            this.$snotify.error(`Tem certeza que deseja excluir esse registro?`, 'Confirmaçāo', {
                timeout: 10000,
                showProgressBar: true,
                closeоnClick: true,
                buttons: [
                    {text: 'Nāo', action: this.$snotify.remove()},
                    {text: 'Sim', action: () => {
                        this.destroy(item)
                        this.$snotify.remove()
                    }},
                ]
            })
        },

        destroy(item){
            this.$store.dispatch('destroyFac', item.id)
                        .then(() => {
                            this.$snotify.success(`Registro excluído com sucesso`, 'Exclusāo')
                            this.$emit('callLoadItems')
                        })
                        .catch(error => {
                            this.$snotify.error('error', error)
                        })
        },

        linkPagination(page){
            this.options.page = page 
            this.loadItems();
        }
    },
}
</script>