import axios from "axios"
import { URL_BASE } from '../../../config/config'

const RESOURCE = 'audits'


export default {
    actions: {
        loadPromisseAudits(context, params) {
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}${RESOURCE}`, {params})
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },
    },

    getters: {

    }

}