<template>
    <div>
        <v-row>
            <v-col>
                <v-card
                    outlined
                    class="pa-3 ma-0"
                    min-height="114"
                >
                    <v-card-title class="pa-0 ma-0 font-weight-light body-2"> 
                        <v-icon small class="mr-2">mdi-face-agent</v-icon>
                        Facs
                    </v-card-title>
                        <v-row dense class="mt-1">
                            <v-col v-for="dash in dashboard.facs" :key="dash.status" class="text-h4 font-weight-light text-center">
                                <router-link v-if="dash.total >= 0" :to="{ 
                                    name: 'admin.facs.index', 
                                    params: {
                                        status: dash.status                                
                                    } 
                                }">
                                    <span :class="dash.status | statusFac('--text')">{{ dash.total }}</span>
                                    
                                    <div class="caption black--text">{{ abbStatusFac(dash.status) }}</div>    
                                </router-link>
                            </v-col>     
                        </v-row>    
                    </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    computed: {
        dashboard() {
            return this.$store.state.dashboard.items.data    
        },
    }, 
    
    methods: {
        abbStatusFac(status) {
            if(status){
                let result = status
                if(status == 'Troca de Atendimento'){ 
                    result = 'Troca de atend.'; 
                } 
                if(status == 'Cliente em Espera'){ 
                    result = 'Espera'; 
                }
                if(status == 'Em atendimento'){ 
                    result = 'Em atend.'; 
                }

                return result;
            }
        }
    }
}
</script>

