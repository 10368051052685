<template>
    <div>
        <v-row>
            <v-col>
                <v-card
                    outlined
                    class="pa-3 ma-0"
                    min-height="114"
                >
                    <v-card-title class="pa-0 ma-0 font-weight-light body-2"> 
                        <v-icon small class="mr-2">mdi-home-city</v-icon>
                        Solicitações internas
                    </v-card-title>
                        <v-row dense class="mt-1">
                            <v-col v-for="dash in dashboard.user_requests" :key="dash.status" class="text-h4 font-weight-light text-center">
                                <router-link v-if="dash.total >= 0" :to="{ 
                                    name: 'admin.userrequests.index', 
                                    params: {
                                        status: dash.status,  
                                        user_request: me.id                           
                                    } 
                                }">
                                    <span :class="dash.status | statusUserRequest('--text')">{{ dash.total }}</span>
                                    
                                    <div class="caption black--text">{{ dash.status }}</div>    
                                </router-link>
                            </v-col>       
                        </v-row>    
                    </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    computed: {
        dashboard() {
            return this.$store.state.dashboard.items.data    
        },

        me () {
          return this.$store.state.auth.me
        },
    },   
}
</script>

